.ant-layout {
    background: #F4F8FB !important;
}

.layout-content {
    background: #F4F8FB !important;
    max-height: calc(100vh - 100px) !important;
    height: calc(100vh - 100px) !important;
    min-height: calc(100vh - 100px) !important;
}

.admin-content {
    min-height: 800px !important;
}

/*
 * @component Layout -> Content
 * @description Asigna el tamaño del content, se utiliza el calculo de toda la pantalla menos el tamaño del header, menos el tamaño del pageHeader
 */

.admin-content {
    min-height: 800px !important;
}

/*
 * @component ProjectManager -> Content
 * @description background color del content de la vista de project manager
 */

.content-bg {
    background: #F4F8FB !important;
}

/*
 * @component ProjectManager -> Col
 * @description background color del col de los card de task
 */

.col-list-task-bg {
    background: #F2EFF5;
}


.list-header-borderless .ant-list-header {
    border: none !important;
}

/*
 * @component CardTaskClient -> List
 * @description Elimina el border-bottom del los items de la lista
 */

.list-border-none ul .ant-list-item {
    border: none !important;
    padding: 12px 0 0 0 !important;
}

/*
 * @component Checkbox 
 * @description Hace el checkbox redondo
 */

.custom-checkbox-circle .ant-checkbox-checked::after {
    border-radius: 50px !important;
    border: 1.5px solid #2C3E50;
    height: 20px;
    width: 20px;
}

/*
 * @component Checkbox 
 * @description Acomoda el input dentro del checkbox
 */

.custom-checkbox-circle .ant-checkbox {
    left: -3px;
    top: -2px;
}

/*
 * @component Checkbox 
 * @description Hace el checkbox inner redondo
 */

.custom-checkbox-circle .ant-checkbox input, .custom-checkbox-circle .ant-checkbox .ant-checkbox-inner {
    border-radius: 50px;
    border: 1.5px solid #2C3E50;
    height: 20px;
    width: 20px;
}

/*
 * @component Divider 
 * @description cambia el color del divider de projectManager
 */

.border-task {
    border: 1px solid #BDBDFE;
}

/*
 * @component RangePicker 
 * @description Hace el ranger transparent con bordes blancos y cuadrado
 */

.filter-range {
    background: transparent !important;
    border: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 0px !important;
    height: 30px;
}

/*
 * @component RangePicker 
 * @description Hace el ranger icon y el placeholder blancos
 */

.filter-range .ant-picker-input input::placeholder, .filter-range .ant-picker-suffix .anticon>svg,
.filter-range .ant-picker-input .ant-picker-range-separator .ant-picker-separator .anticon>svg {
    color: #fff !important;
}

/*
 * @component RangePicker 
 * @description El borderBottom cuando seleccionas un campo lo pone blanco
 */

.filter-range .ant-picker-active-bar {
    background: #fff !important;
}

/*
 * @component Button 
 * @description Hace el borde transparente
 */

.button-borderless {
    border: 1px solid transparent !important;
}

/*
 * @component Button 
 * @description Boton de descarga de los filtros, lo hace mas grande y el icono blanco
 */

.button-download, .button-download svg {
    font-size: 25px !important;
    color: #fff;
}

/*
 * @component Header 
 * @description Cambia el color del Header y la fuente
 */

.ant-layout-header {
    color: #FFFFFF;
    line-height: 64px;
    background: #2D348C !important;
}

/*
 * @component PageHeader 
 * @description Cambia el color del PageHeader y la fuente, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */

.ant-page-header {
    box-sizing: border-box;
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    padding: 16px 24px;
    background-color: #2D348C !important;
}

/*
 * @component PageHeader 
 * @description Cambia  la fuente del titulo del PageHeader, 
 * se esta sobreescribiendo la clase nativa ya que
 * no hay variables para modificarlo
 */

.ant-page-header-heading-title {
    margin-right: 12px;
    margin-bottom: 0;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 3px;
    color: #FFFFFF !important;
    font-weight: 600;
    font-size: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


/*
 * @component Button 
 * @description Ajusta el boton del Upload a la esquina inferior derecha 
 */

.btn-upload-delete {
    display: block;
    position: absolute !important;
    bottom: 20px;
    right: 0%;
}

/*
 * @component List->Header 
 * @description Cambia el texto a mayusculas del header de la lista
 */
.header-list * {
    text-transform: uppercase !important;
}

/*
 * @component None
 * @description Asigna un borde de 16px 
 */
.border-16 {
    border-radius: 16px;
}

/*
 * @component Tag
 * @description Le quita el border radius al tag
 */
.modulo-tag {
    border-radius: 0px !important;
}



/*
 * @component TableContainerXML 
 * @description Esto es para el diseño de las tablas que permiten visualizar el XML
 */
.table-container {
    width: 100%;
    overflow: auto;
    max-height: 500px;
}

/*
 * @component TableContainerXML 
 * @description Para los divs-rows de la tabla
 */
.div-row {
    display: flex;
}


/*
 * @component TableContainerXML 
 * @description Para lso nombres de los elementos de la table
 */
.div-row .div-key {
    width: 25%;
    border-bottom: solid 1px black;
    background-color: #90A7F8;
    border-right: solid 1px black;
    border-left: solid 1px black;
    padding-left: 0.5rem;
    min-width: 120px;
}

/*
 * @component TableContainerXML 
 * @description Para los titutlos de los elementos de la table XML
 */
.div-title {
    width: 100%;
    background-color: #0047FF;
    color: white;
    border-bottom: solid 1px black;
    padding-left: 1rem;
}

/*
 * @component TableContainerXML 
 * @description No se, preguntarle a manuel el lo hizo
 */
.div-string {
    border-bottom: solid 1px black;
    border-right: solid 1px black;
    width: 75%;
    padding-left: 1rem;
    min-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-height: 200px;
}

/*
 * @component TableContainerXML 
 * @description Colores de los bordes de la tabla XML
 */
.factura-xml-table {
    border-left: solid 1px black;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}

/*
 * @component TableContainerXML 
 * @description para mantener la estructura de las posiciones de los elementos
 */
.table-container thead th {
    position: sticky;
    top: 0;
    z-index: 1;
}

/*
 * @component TableContainerXML 
 * @description Mismo punto,pero para todo el TH global, no solamnete para headers
 */
.factura-xml-table th {
    text-transform: capitalize;
    padding-right: 2rem;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
    background-color: #cacaca;
}

/*
 * @component TableContainerXML 
 * @description Para los bordes de los TDS
 */
.factura-xml-table td {
    text-transform: capitalize;
    border-right: solid 1px black;
    border-bottom: solid 1px black;
}

/*
 * @component Icon 
 * @description Color de las estrellas para usuarios premium
 */
.star-filled {
    color: rgba(255, 255, 0) !important;
}

/*
 * @component Button 
 * @description Para el boton de los filtros de transacciones
 */
.button-plus {
    background: #FFFFFF;
    width: 50px !important;
    border: none !important;
    border-radius: 100px !important;
    align-items: center;
    color: #2E2E3A;

}

/*
 * @component Button 
 * @description Para el boton de los filtros de transacciones
 */
.btn-traspaso {
    background: #CF45FF !important;
    border: none !important;
    margin-right: 0.5rem !important;
}

/*
 * @component Tag 
 * @description Estilos del tag de Actualiza Saldo del modal de transacciones
 */
.tag-actualiza-saldo {
    font-size: 12px;
    height: 20px !important;
    width: 98px;
    margin-bottom: 7px !important;
    display: block !important;
    line-height: 20px !important;

}

.radio-blue-switch {
    display: flex !important;
    float: right;
}

.radio-blue-switch .ant-radio-button-wrapper {
    background: #2D348C;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0 16px !important;
    border: 1px solid #2D348C !important;
}

.radio-blue-switch .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border: 1px solid #2D348C !important;
    background: #2D348C;
    text-transform: uppercase;
    color: #DADADA;
    font-weight: 600;
}

.radio-blue-switch .ant-radio-button-wrapper:first-child {
    border-radius: 10px 0 0 10px !important;
}

.radio-blue-switch .ant-radio-button-wrapper:last-child {
    border-radius: 0 10px 10px 0px !important;
}

.radio-blue-switch .ant-radio-button-wrapper:not(:first-child)::before {
    display: none !important;
}

.radio-blue-switch .ant-radio-button-wrapper-checked {
    font-size: 16px !important;
    font-weight: bold !important;
}


.text-red {
    color: #FF4D4F !important;
}

.text-yellow {
    color: #FAAD14 !important;
}

.text-green {
    color: #52C41A !important;
}

.color-waiting {
    background-color: #FAAD14 !important;
}

.color-accepted {
    background-color: #52C41A !important;
}

.color-rejected {
    background-color: #FF4D4F !important;
}

.inline-list .ant-list-item-meta-content {
    display: flex !important;
}

.inline-list .ant-list-item-meta-content .ant-list-item-meta-title {
    max-width: 100px;
    flex-wrap: wrap;
    overflow-wrap: break-word;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    color: #06152B;


}

.inline-list .ant-list-item-meta-content .ant-list-item-meta-description {
    margin-left: 10px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: 350;
    font-size: 15px;
    line-height: 20px;
    color: #2D348C;
}


/*
 * @component Title 
 * @description Estilos del titulo de los cards
 */
.element-title {
    font-family: Lato;
    font-weight: 600;
    font-size: 1.25rem !important;
    color: #858997 !important;
}
.pay-disabled{
    pointer-events: none;
    opacity: 0.6;
    background: rgba(228, 224, 224, 0.685);  
    filter: alpha(opacity=50);
    zoom: 1;  
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";  
    -moz-opacity: 0.5; 
    -khtml-opacity: 0.5; 
    transition: background-color 0.5s linear; 
}
@media screen and (max-height:1024px) {
    .element-title {
        font-size: 1rem;
    }

}