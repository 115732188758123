@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;600&display=swap');



/*
* @component Landing -> Content
 * @description Ajusta el height del content para que abarque toda la pantalla
 */
 .landing-main {
    width: 100%;
    min-height: calc(100vh - 192.74px) !important;
    max-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
    .ant-carousel{
        max-width: 100vw;
        max-height: 100vh;
        width: 100%;

        .slick-track{
            display: flex !important;
        }

        .slick-slide{
            height: inherit !important;
            max-height: 100vh;

            & > div{
                height: 100%;
                max-height: 100vh;
            }
        }
    }
    
    .carousel{
        &-slide{
            width: 100%;
            height: 100%;
        }

        &-image{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-content{
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 24px;
        background-color: rgba(0, 0, 0, 0.7);
    }
}

@media (max-width: 500px){
    .landing-main{
        height: 100vh;

        .ant-carousel{
            height: 100vh;

            .slick-slide{
                height: 100vh;

                & > div{
                    height: 100vh;
                }
            }
        }
    }
}

.landing-main-cta{
    width: 100%;
    max-width: 1300px;
    
    .main-cta{

        &-left{
            display: flex;
            flex-direction: column;
            justify-content: center;

            *{
                color: white;
            }
        }

        &-title{
            margin-top: -100px;
            font-size: 56px;
            font-weight: 700;
            line-height: 77px;
        }

        &-subtitle{
            font-size: 36px;
            font-weight: 500;
            line-height: 50px;
        }

        &-button{
            width: fit-content;
            font-size: 18px;
            font-weight: 500;
            background-color: #ED6843;
            padding: 25px 30px;
            border: none;
            cursor: pointer;
        }

        &-stack{
            width: 100%;
            position: relative;
        }

        &-phone{
            width: 100%;
            object-fit: contain;
        }

        &-links{
            width: 100%;
            position: absolute;
            bottom: 48px;
            left: 0;
            right: 0;

            display: flex;
            flex-direction:column;
            align-items: center;
            row-gap: 16px;
        }
    }
}

@media (max-width: 1200px){
    .landing-main-cta{
        .main-cta{
            &-title{
                margin-top: -60px;
                font-size: 42px;
                line-height: 50px;
            }  
            
            &-subtitle{
                font-size: 28px;
                line-height: 30px;
            }
    
            &-button{
                padding: 20px 25px;
            }

            &-links{
                a{
                    width: 70%;
                }

                img{
                    width: 100%;
                }
            }
        }
    }
}
@media (max-width: 992px){
    .landing-main-cta{
        .main-cta{
            &-title{
                margin-top: 0px;
                font-size: 36px;
                line-height: 45px;
            }  
            
            &-subtitle{
                font-size: 24px;
                line-height: 30px;
            }
    
            &-button{
                padding: 18px 20px;
            }

            &-links{
                max-width: 216px;
                bottom: 28px;
                row-gap: 12px;

                a{
                    width: 80%;
                }

                img{
                    width: 100%;
                }
            }
        }
    }
}

@media (max-width: 768px){
    .landing-main-cta{
        .main-cta{
            &-title{
                font-size: 28px;
                line-height: 30px;
            }  
            
            &-subtitle{
                font-size: 20px;
                line-height: 22px;
            }
    
            &-button{
                padding: 15px 17px;
            }

            &-right{
                display: none !important;
            }
        }
    }
}

.landing-cta-app{
    padding: 50px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1E2843;

    .cta-app{
        width: 100%;
        max-width: 1300px;

        span, p{
            color: white;
        }

        &-info{
            display: flex;
            flex-direction: column;
            row-gap: 12px;
        }

        &-title{
            font-size: 28px;
            font-weight: 800;
        }
        
        &-subtitle{
            font-size: 20px;
        }

        &-links{
            display: flex;
            flex-direction: column;
            row-gap: 12px;

            img, a{
                width: 100%;
            }
        }
    }
}

.landing-industrias{
    padding: 115px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 28px;  

    .industrias{
        &-carousel{
            width: 100%;
            max-width: 1300px;
        }
    }

    .carousel{
        &-slide{
            width: 100%;
            max-width: 270px;
            height: 172px;
            border-radius: 10px;
            overflow: hidden;
            
            div{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 14px;

                background-size: cover;
                background-color: rgba(0,0,0,0.4);
                background-blend-mode: multiply;
            
                font-size: 22px;
                font-weight: 700;
                color: white;
            }
        }

        &-control{
            svg{
                font-size: 28px;
                color: black;
            }

            button{
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
}

.landing-steps{
    display: flex;
    justify-content: center;
    padding: 24px;
    background-color: #ED6843;

    .steps{
        width: 100%;
        max-width: 1300px;

        &-data{
            font-size: 60px;
            font-weight: 800;
            color: white;
            text-align: center;
        }

        &-text{
            font-size: 24px;
            font-weight: 700;
            color: white;
            text-align: center;
        }
    }

    .ant-col{
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 500px) and (max-width: 800px){
    .landing-steps{
        .steps{
            &-data{
                font-size: 50px;
            }
    
            &-text{
                font-size: 18px;
            }
        }
    }
}

.landing-productos{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 64px 24px;
    row-gap: 28px;

    .productos{
        &-list{
            width: 100%;
            max-width: 1300px;
        }

        &-title{
            text-align: center;
        }
    }
    
}


.landing-cliente{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    padding: 100px 24px;

    & > *{
        width: 100%;
        max-width: 1300px;
        min-width: auto !important;
    }

    .ant-divider{
        border-color: rgba(0, 0, 0, 0.85);;
    }

    .cliente{
        &-title *{
            font-size: 44px;
            font-weight: 800;
            line-height: 22px;
        }

        &-options{
            margin-top: 24px;
        }

        &-option{
            display: grid;
            grid-template-columns: 57px 1fr;
            grid-template-areas: 'Icon Title' 'Icon Description';
            column-gap: 12px;
            row-gap: 2px;
        }

        &-icon{
            grid-area: Icon;
            height: 57px;
            width: 57px;
        }

        &-highlight{
            grid-area: Title;
            font-size: 20px;
            font-weight: 700;
        }

        &-descripcion{
            grid-area: Description;
            font-size: 20px;
        }
    }
}

.landing-pov{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    padding: 100px 24px;
    background-color: #E94926;

    & > *{
        width: 100%;
        max-width: 1300px;
        min-width: auto !important;
    }

    .ant-divider{
        border-color: white;
    }

    *{
        color: white !important;
    }

    .cliente{
        &-title *{
            font-size: 44px;
            font-weight: 800;
            line-height: 22px;
        }

        &-options{
            margin-top: 24px;
        }

        &-option{
            display: grid;
            grid-template-columns: 57px 1fr;
            grid-template-areas: 'Icon Title';
            column-gap: 12px;
            align-items: center;
        }

        &-icon{
            grid-area: Icon;
            height: 57px;
            width: 57px;
        }

        &-highlight{
            grid-area: Title;
            font-size: 20px;
            font-weight: 700;
        }
    }
}

.landing-nuestros-clientes{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    padding: 100px 24px;
    background-color: white;

    & > *{
        width: 100%;
        max-width: 1300px;
        min-width: auto !important;
    }

    .ant-divider{
        border-color: rgba(0, 0, 0, 0.85);;
    }

    .nuestros-clientes{
        &-title {
            font-size: 44px;
            font-weight: 800;
            line-height: 48px;
        }
        
        &-carousel{
            width: 100%;
            max-width: 1300px;
        }
    }

    .carousel{
        &-slide{
            width: 100%;
            max-width: 270px;
            height: 172px;
            border-radius: 10px;
            overflow: hidden;
            
            div{
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                padding: 14px;

                background-size: contain;
                background-position: center;
                background-repeat: no-repeat;
                background-blend-mode: multiply;
            
                font-size: 22px;
                font-weight: 700;
                color: white;
            }
        }

        &-control{
            svg{
                font-size: 28px;
                color: black;
            }

            button{
                width: 100%;
                height: 100%;
                border: none;
            }
        }
    }
}

@media (max-width: 800px){
    .landing-nuestros-clientes{
        .nuestros-clientes{
            &-list{
                justify-content: center;
            }
        }
    }
}

.landing-about{
    width: 100%;
    height: 100vh;
    background-image: url('../../../public/images/bg-1.png') !important;
    background-size: cover !important;
    overflow: initial;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    
    .about-video{
        width: 100%;
        max-width: 1200px;
        height: 100%;
        max-height: 720px;
    }
}



@media screen and (max-height: 741px) and (min-width: 1192px) {
    
    .landing-main-content {
        padding-top: 13% !important;
    }
    .main-cta-right {
        padding-top: 1% !important;
    }
}