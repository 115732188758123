.collapse-productos .ant-collapse-item{
    margin-bottom: 0.5rem;
}

.collapse-productos .ant-collapse-header {
   display: flex;
    background: #2D348C;
    border: 0px;
    border-radius: 8px!important;
    color: #FFFFFF;
    
}
.collapse-productos .ant-collapse-item .ant-collapse-content{
    background: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    line-height: 50px;
    color: #FFFFFF ;
    font-weight: 600;
}

.header-collapse span {
    font-family: Poppins;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF ;
}

.collapse-text-big {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}

.collapse-productos .ant-collapse-item{
    border:  none !important;
}