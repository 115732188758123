/*
 * @component Dropdown
 * @description estilos de los filtros
 */
.dropdown-filter, .dropdown-filter span {
        background: inherit;
        font-family: Poppins;
        color: #FFFFFF;
        font-weight: 500;
        font-size: 15px;
        line-height: 35px;
        letter-spacing: 0.05em;
}


/*
 * @component Dropdown
 * @description estilos de los filtros al hacer hover
 */
.dropdown-filter:hover, .dropdown-filter span:hover {
        color: #BABABA;
}
/*
 * @component Button
 * @description estilos del boton de filtros select
 */
.button-filter-large{
        background: #FFFFFF!important;
        color: #000!important;
        font-weight: 300!important;
}
/*
 * @component None
 * @description estilos del circulo naranja del boton de filtros
 */
.point{
        height: 10px;
        width: 10px;
        background: #FF8743;
        border-radius: 50px;
        display: inline-block;
        margin: 0 2px;
}

.filter-sub-menu a{
        color:#000!important;
      
}