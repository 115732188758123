.table-products,
.list-totals
{

    .ant-input-number-input{
        text-align: end !important;
    }

    .ant-list-sm .ant-list-item{
        padding: 2px 16px !important;
    }
}

.list-totals {
    border-bottom: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    border-right: 1px solid #f0f0f0;
}