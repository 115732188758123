.step-card {
    /*max-width: 400px;
    min-width: 350px;*/
    width: 100%;
}



/*
 * @description estilos del meta del card del titulo del card
 */
.step-tracker .ant-steps-item-description {
    width: 100%;
    margin-left: 10px;
}

/*
 * @description estilos del meta del card del titulo del card
 */
.step-tracker .ant-steps-item-title {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #333333;

}

.card-task-list .ant-list-items .ant-list-item {
    padding: 0.47rem;
}

.card-task-list-meta .check-blue>.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #0089ED !important;
    border: 1px solid #0089ED !important;
}

.card-task-list-meta .check-yellow>.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #DAC401 !important;
    border: 1px solid #DAC401 !important;
}

.card-task-list-meta .check-pink>.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #B354E0 !important;
    border: 1px solid #B354E0 !important;
}
.card-task-list-meta .check-red>.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #FF0000 !important;
    border: 1px solid #FF0000 !important;
}
.card-task-list-meta .check-green>.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #1DD667 !important;
    border: 1px solid #1DD667 !important;
}

.card-task-list-meta .ant-checkbox-disabled .ant-checkbox-inner::after {
    border-color: rgb(255, 255, 255) !important;
}

.step-tracker .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
    color: rgb(255, 255, 255) !important;
}

.step-tracker .step-color-blue .ant-steps-item-container .ant-steps-item-icon {
    background-color: #0089ED !important;
    border: 1px solid #0089ED !important;
}

.step-tracker .step-color-blue  .step-icon{
    color: #0089ED !important;
}

.step-tracker .step-color-yellow .ant-steps-item-container .ant-steps-item-icon {
    background-color: #DAC401 !important;
    border: 1px solid #DAC401 !important;
}

.step-tracker .step-color-yellow .step-icon {
    color: #DAC401!important;
}

.step-tracker .step-color-pink .ant-steps-item-container .ant-steps-item-icon {
    background-color: #B354E0 !important;
    border: 1px solid #B354E0 !important;
}
.step-tracker .step-color-pink .step-icon {
    color: #B354E0!important;
}

.step-tracker .step-color-green .ant-steps-item-container .ant-steps-item-icon {
    background-color: #36F905 !important;
    border: 1px solid #36F905 !important;
}


.step-tracker .step-color-blue .ant-steps-item-container .ant-steps-item-tail::after {
    width: 2px !important;
    background-color: #0089ED !important;
}

.step-tracker .step-color-yellow .ant-steps-item-container .ant-steps-item-tail::after {
    width: 3px !important;
    background-color: #DAC401 !important;

}

.step-tracker .step-color-pink .ant-steps-item-container .ant-steps-item-tail::after {
    width: 3px !important;
    background-color: #B354E0 !important;

}

.step-tracker .step-color-green .ant-steps-item-container .ant-steps-item-tail::after {
    width: 3px !important;
    background-color: #36F905 !important;

}

/* 2560 x 1440 */
@media (min-width: 1440px) {
    .step-tracker {
        height: calc(1170px - 230px);
        max-height: calc(1260px - 230px);

    }
}

/* 1680 x 1050 */
@media (max-width: 1040px) {
    .step-tracker {
        height: calc(860px + 100px);
        max-height: calc(860px + 100px);

    }

}

/* 1200 x 800 */
@media (max-width:1024px) {
    .step-card {
        min-width: 200px!important;
    }
}

