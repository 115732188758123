h5.title-blue, .title-blue
{
    font-family: Poppins;
    font-style: normal;
    font-weight: bolder !important;
    font-size: 16px;
    line-height: 24px;
    color: #1F4173 !important;
}

.header-list strong{
    font-family: Poppins !important;
    font-weight: bolder !important;
    font-size: 15px !important;
    color: #1F4173 !important;
    text-transform: uppercase !important;
}


.item-orden-id{
    color: #003AD2 !important;
    font-weight: bolder !important;
}


.text-blue{
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    color: #1F4173 !important;
}

.item-estatus{
    border-radius: 5px;
    background: #e6ecfb !important;
    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    align-items: center;
    text-align: center;
    color: #003AD2 !important;
    padding: 2px 10px;
    
}


.row-list-item {
    border-bottom:  solid 1px #e6eaf0  !important;
    margin-bottom: 10px !important;
}


.ant-radio-group.item-radio .ant-radio-button-wrapper {
 border-color: transparent !important;
 margin: 0px 1px !important;
}

.ant-radio-group.item-radio span {
    color : #1F4173 !important;
 

    font-family: Poppins !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 21px !important ;
    border-color: transparent !important;

}

.ant-radio-group.item-radio   .ant-radio-button-wrapper-checked{
    background: #e9ecf1 !important;
    border-radius: 5px !important;

}
.ant-radio-group.item-radio   .ant-radio-button-wrapper-checked span{
    font-weight:  bolder !important;
}

.ant-radio-group.item-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before ,
.ant-radio-button-wrapper:not(:first-child)::before,
.ant-radio-group.item-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: transparent !important;
    background: transparent !important;
}

.btnAddPlusFloat{
    border-radius: 50px !important;
    border: none!important;
    width: 80px !important;
    height: 80px!important;
    bottom: 30px;
    right: 30px;
    z-index: 10;
    float: right!important;
    position: fixed !important;
background: linear-gradient(126.63deg, #2D348C 11.98%, #2D348C 83.35%) !important;
box-shadow: 5px 5px 8px rgba(0, 0, 0, 0.35), 0px 4px 4px rgba(0, 0, 0, 0.25) !important;

}

.btn-card{
background: #E6EAF0 !important; 
border-radius: 5px !important;
color: #1F4173 !important;
font-weight: bolder !important;
font-size: 20px !important;
padding: 0px !important;
width:  40px;
height: 40px;

}

.btn-compra{
    background-color: #17D44C !important;
    border: none !important;
}

.btn-compra[disabled]{
    background-color: #17d44cb3 !important;
    border: none !important;
}

.spn-status-order{
    width:  15px;
    height: 15px;
    border-radius: 50px;
    background: white !important;
    border: solid 3px #CCC;
    display: block !important;
}


.center{
    text-align: center !important;
}

.spn-card-estatus{
    background: #d7fecd !important;
    border-radius: 50px;
    color: #29BB05 !important;
    padding: 5px 10px;
}

.text-gray{
    color: #858997 !important;
}

.btn-opc-circle{
    padding: 0px !important;
    width:  33px !important;
    height: 33px !important;
    border-radius: 50px !important;
    border-color: transparent !important;
    margin: 0px 5px;
}

.btn-opc-circle.btn-small, .btn-opc-circle.btn-small svg {
    width: 25px !important;
    height: 25px !important;
}

.card-cotizacion .ant-card-head {
    display: none !important;
}

.ant-steps {
    display: block !important;
}

.collapse-almacenes .ant-collapse-item{
    margin-bottom: 0.5rem;
}

.collapse-almacenes .ant-collapse-header {
   display: flex;
    background: #2D348C;
    border: 0px;
    border-radius: 8px!important;
    color: #FFFFFF;
    
}
.collapse-almacenes .ant-collapse-item .ant-collapse-content{
    background: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    line-height: 50px;
    color: #FFFFFF ;
    font-weight: 600;
}

.header-collapse span {
    font-family: Poppins;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF ;


}

.collapse-text-big {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}

.collapse-almacenes .ant-collapse-item{
    border:  none !important;
}

.tabs-inventario .ant-tabs-nav-list{
    background: white;
    padding: 0.8rem;
    border-radius: 10px;
}

.tabs-inventario .ant-tabs-tab-active{
    background: #deddf9 !important;
    color: #5C54E0 !important;
    border: #FFFFFF;
    border-radius: 30px;
    padding: 0 10px;
    width: 100px;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
}
.tabs-inventario .ant-tabs-tab{
    width: 100px;
    margin: 0 !important;
    justify-content: center;
    text-align: center;
}

.tabs-inventario .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #5C54E0 !important;
}

.tabs-inventario .ant-tabs-ink-bar{
    display: none;
}


.spn-muesca{
position: absolute;
width: 8px;
height: 75%;
left: 0px;
top:10px;
background: #006BFF;
}

.border-none{
    border: none !important;
    padding-bottom: 5px !important;
}

.compras-list .ant-list-item{
    border-bottom: none !important;
    padding: 0.25rem 0 !important;
}

.ant-tabs-top > .ant-tabs-nav::before{
    border:  none !important;
}