.marketplace{
    width: 100%;
    min-height: 100vh;
    background-image: url('../../../../public/images/bg-3.jpg') !important;
    background-size: cover !important;
    overflow: initial;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;

    display: flex;
    justify-content: center;
    padding: 3rem 24px;


    // Establecer color del texto en Typography y Button
    *.ant-typography, *.ant-btn span{
        color: white !important;
    }

    // Lo contenido en el espacio/card azul 
    &-content{
        width: 100%;
        height: min-content;
        max-width: 1000px;
        padding: 3rem 4rem;
        background-color: #2D348C;
    }

    // Logo y brand de la empresa
    &-brand{
        display: flex !important;
        justify-content: center;

        img{
            max-width: 250px;
        }
    }

    // Titulares principales
    &-header{
        text-align: center;
    }
}

@media (max-width: 600px) {
    .marketplace{
        padding: 24px 12px;

        &-content{
            padding: 24px 12px;
        }

        &-actions{
            justify-content: center !important;
        }
    }
}

// Estilos para los pasos del proceso
.marketplace-steps{
    .ant-steps{
        display: flex !important;
        justify-content: center !important;
    }

    .ant-steps-item-content{
        width: 200px !important;
        margin: 1rem 0 0 -30px !important;

        *{
            color: white !important;
        }
    }

    .ant-steps-item-tail::after{
        background-color: white !important;
    }

    // Estilos para los puntos de los pasos
    .steps-dot{
        width: 35px;
        height: 35px;
        margin: -12px 0 0 -15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #2D348C;
        border: 1px solid white;
        border-radius: 50%;

        div{
            font-size: 1rem !important;
            font-weight: bold !important; 
            color: white !important;
            margin-bottom: 0 !important;
        }

        &.active{
            background-color: white;
        }

        &.active div{
            color: #2D348C !important;
        }
    }

    &-button{
        background-color: #E05447 !important;
        border: none !important;
        border-radius: 2rem !important;
        color: white !important;
        font-weight: 700 !important;
        padding: 0.5rem 3rem !important;
    }
}


@media (max-width: 768px){
    .marketplace-steps{
        display: none !important;

        &-button{
            padding: 0.5rem 2rem !important;
        }
    }
}

.marketplace-cotizador{
    .cotizador{
        &-title{
            text-align: center;
        }

        &-subtitle{
            margin-bottom: 0px;
        }

        &-actions{
            display: flex !important;
            justify-content: flex-end;

            .action-add{
                background-color: #1890FF !important;
                border: none !important;
            }
        }

        &-empty{
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 24px;
        }

        &-productos .ant-list-item{
            border-bottom: none !important;
        }
    }
}


.marketplace-auth{
    
    &-logged{
        padding: 40px 0px;

        .logged{
            &-header{
                text-align: center;
            }

            &-actions{
                display: flex !important;
                flex-direction: column;
                row-gap: 12px;
                align-items: center;
            }
    

            &-continue{
                max-width: 200px;
                background-color: #E05447 !important;
                border: none !important;
                border-radius: 2rem !important;
                color: white !important;
                font-weight: 700 !important;
                padding: 0.5rem 2rem !important;
            }
        }
    }

    .auth-container{
        width: 100%;
        max-width: 300px;
        margin: 2em auto; 
        text-align: center;
    }

    label{
        color: white !important;
    }

    .auth-button{
        background-color: #E05447 !important;
        border: none !important;
        border-radius: 2rem !important;
        color: white !important;
        font-weight: 700 !important;
        padding: 0.5rem 1.5rem !important;
    }
}



.marketplace-info{
    label {
        color: white !important;
    }

    input::placeholder{
        color: rgba(255, 255, 255, 0.7) !important;
    }
}















/*
 * @component Marketplace -> IconCheck
 * @description Establece el tamaño del icono 
 */
.marketplace-icon-mark{
    width: 4rem;
    height: 4rem;
}

/*
 * @component Marketplace -> Title
 * @description Establece el tamaño del titulo
 */
 @media (max-width: 768px){
    .marketplace-title{
        font-size: 1.25rem !important;
    }
}

/*
 * @component Marketplace  
 * @description Establece fondo y padding en el formulario de pasos
 */
.marketplace-form{
    background-color: #2D348C !important;
    padding: 3rem 4rem;
}


@media (max-width: 768px){
    .marketplace-form{
        background-color: #2D348C !important;
        padding: 2rem 1rem;
    }
}

/*
 * @component Marketplace 
 * @description Establece el color blanco en los textos 
 */
.marketplace-form, .marketplace-form * {
    color: white !important;
}

/*
 * @component Marketplace -> Input 
 * @description Establecer estilos generales para todos los inputs
 */
.marketplace-content .ant-input, .marketplace-content .ant-input-number-input, .marketplace-content .ant-input-password{
    background-color: rgba(255, 255, 255, 0.2) !important;
    color: white !important;
}

/*
 * @component Marketplace -> Input 
 * @description Establecer estilos generales para todos los inputs tipo password
 */
.marketplace-content .ant-input-password .ant-input{
    background-color: transparent !important;
}

/*
 * @component Marketplace -> Input 
 * @description Establecer padding para todos los inputs
 */
.marketplace-content .ant-input-number-input{
    padding: 8px 11px !important;
}

/*
 * @component Marketplace -> InputNumber 
 * @description Hacer correciones en el largo, fondo y borde que vienen en la libreria
 */
.marketplace-content .ant-input-number{
    width: 100% !important;
    background-color: transparent !important;
    border: none !important;
}

/*
 * @component Marketplace -> Input
 * @description Establecer el color de los placeholders
 */
.marketplace-content .ant-input::placeholder{
    color: rgba(255, 255, 255, 0.5);
}

/*
 * @component Marketplace -> Button
 * @description Establcer y Corregir estilos de los botones "Anterior" y "Siguiente"
 */


/*
 * @component Marketplace -> StepCuenta -> Form.Item 
 * @description Centrar el label del formulario
 */
.cuenta-form-item .ant-form-item-label{
    text-align: center !important;
}

/*
 * @component Marketplace -> StepCuenta -> Link
 * @description Darle color al link
 */
.cuenta-form-link{
    border: none !important;
    background-color: transparent !important;
    font-weight: 500 !important;
    padding: 0 0 0 0.35rem !important;
}

.cuenta-form-link span{
    color: #E05447 !important;
}

/*
 * @component Marketplace -> StepCotizador -> Button
 * @description Establecer el fondo y quitar el borde al boton
 */
.cotizador-button-add{
    background-color: #1890FF !important;
    border: none !important;
}

/*
 * @component Marketplace -> StepCotizador -> Button 
 * @description Establcer peso y decoracion del texto, y el fondo sin borde del boton
 */
.cotizador-text-add{
    font-weight: bold;
    text-decoration: underline;
    background-color: transparent !important;
    border: none !important;
}





.button-login-cotizador{
    border-radius: 100px !important;
    margin-top: 1em !important;
    background-color: #E05447 !important;
}

.button-login-cotizador span{ 
    text-transform: uppercase !important;
    font-size: 12px;
    position: relative;
    bottom: 3px;
}