.terminos{
    &-main{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 45px 24px;

        & > .ant-row{
            max-width: 1300px;
        }
    }
}