.radio-payment-selection{
    width: 100%;
    justify-content: center;
    text-align: center;
    .button-payment-selection{
        width: 50%;
        height: unset;
        text-align: center;
        line-height: 18px;
        
        .icon{
            margin-top: 4px;
            display: inline-block;
        }
        .credit-card{
            margin-right: 8px;
        }

        .text-payment-selection{
            display: inline-block;
            position: relative;
            bottom: 10px;
            font-weight: bold;
            font-size: 14px;
        }

    }
}

