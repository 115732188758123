@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;600&display=swap');


.footer{
    display: flex;
    justify-content: center;
    padding: 60px 24px !important;
    background-color: #1E2843 !important;

    *{
        color: white !important;
    }

    .ant-divider{
        border-color: white;
    }

    h3.ant-typography{
        font-size: 18px;
        font-weight: 700;
    }

    .footer{
        &-wrapper{
            width: 100%;
            max-width: 1300px;
        }

        &-logo{
            margin-bottom: 37px;
        }

        &-directorio{
            display: flex;
            flex-direction: column;
            row-gap: 40px;
        
            nav{
                display: flex;
                flex-direction: column;
                row-gap: 27px;
            }
        }

        &-directorio{
            display: flex;
            flex-direction: column;
            row-gap: 15px;
        
        }

        &-listado{
            display: flex;
            flex-direction: column;
            row-gap: 15px;
        }

        &-sucursales{

            .sucursal{
                font-size: 16px;
            }

            strong{
                font-size: 20px;
            }
        }
    }
}