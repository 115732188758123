.drawer-seleccionar-productos{
    .ant-drawer-body {

        padding: 0;
        margin-top: 24px;
        padding-bottom: 24px;
        // margin-bottom: 24px;
    }

    .drawer-section{
        padding: 0 24px;

        &.sticky {
            position: sticky;
            top: 0;
            background: white;
            z-index: 2;

            &.is-sticky {
                box-shadow: 0 .125rem .25rem rgba(0,0,0,.075);
            }
        }        
    }
}