/*
 * Component List
 * @description estilos del meta del card del titulo del card
 */
.product-list-pm .ant-list-header {
    padding: 12px 0px 0px 0px !important;
    border-bottom: none !important;
}

.product-list-header{
    margin-top: 2em;
}

/*
 * Component List
 * @description Lista de Productos del Cotizador
 */
.products-list{
    /*
    * Component ListItem
    * @description Elemento del producto
    */
    .product-list-item {
        position: relative;
        background: #FFFFFF;
        box-shadow: -1px 5px 10px rgba(32, 56, 85, 0.05);
        border-radius: 8px;
        // min-width: max-content;
        width: 100%;

        padding: 1em !important;
        margin: 1em;
   
        /*
        * @component CSS para el radio del product list, para acomodarlo
        * @description Elemento del producto
        */ 
        .product-list-item-radio{
            width: 137.2px;
        }

        /*
        * @component Contenido del Item.Radio
        * @description Contenido del formulario individual por proveedor
        */ 
        .product-list-item-content{
            // width: 300px !important;
            // min-width: 300px !important;
            align-items:center;
        }

        /*
        * @component Radio
        * @description Producto de Radio
        */ 
        .radio-product{
            margin: 0 2.5em 1.2em 2.5em;
            width: 100%;
            /*
             * @component Radio de selección
             * @description Producto de Radio
             */ 
            .ant-radio{
                position: relative;
                top: 33px;      
                margin-right: 1em;                  
            }
        }
    }

    .product-item {


        .product-description-content{
            line-height: 0.5;
        }

    }




    // .product-item > .product-info{
    //     margin-top: 15px;

    // }

    // .product-info{
    //     position: relative;
    // }
    
    // .product-description{
    //     position: absolute !important;
    //     top: -15px !important;
    //     left: 0 !important;
    //     font-style: italic;
    //     font-size: 12px;
    //     // font-weight: lighter;
    // }
}

/*
 * @component OrderList
 * @description Lista de Ordenes
 */ 
.order-list-item {
    background: #FFFFFF;
    box-shadow: 0px 30px 60px rgba(32, 56, 85, 0.15);
    border-radius: 8px;
    margin: 12px 0;
    padding: 5px 0 !important;
    color: #121212;
}

.orden-embarque-upload .ant-upload{
    display: block !important;
}

/*
 * @component Tag
 * @description estilos del meta del card del titulo del card
 */
.tag-card-blue {
    display: inline-block;
    background: #CEECFD;
    border-radius: 50px;
    min-width: 100px;
    color: #1778B0 !important;
    max-height: 30px;
    line-height: 15px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;

    padding: 6px 15px;
}

/*
 * @component Tag
 * @description estilos del meta del card del titulo del card
 */
.tag-card-purple {
    display: inline-block;
    background: #f0ddf9;
    border-radius: 50px;
    min-width: 100px;
    color: #B354E0 !important;
    max-height: 30px;
    line-height: 15px;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;

    padding: 6px 15px;
}


.tag-card-pink {
    display: block;
    background: rgba(240, 221, 249,1);
    border-radius: 50px;
    min-width: 100px;
    width: auto;
    max-width: 200px;
    color: #B354E0 !important;
    padding: 4px;
    height: 27px;
    max-height: 30px;
    line-height: 15px;
    font-weight: 400;
    text-align: center;
}
/*
 * Component Card
 * @description estilos del meta del card del titulo del card
 */
.card-medium-height {
    height: 270px;
    max-height: 270px;
}

/*
 * Component Card
 * @description estilos del meta del card del titulo del card
 */
.card-small-height {
    // height: 127px;
    max-height: 140px;
}

.card-small-height.padding-sm .ant-card-body{
    padding: 0.5rem 1rem !important;
}

.product-image {
    /*border: 1px solid #000;*/
    border-radius: 4px;
}

.product-text-name {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.3px;
    color: #121212;
}

.product-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    letter-spacing: 0.3px;
    color: #686868;
    margin-top: 3px !important;

    .ant-input-number-handler-wrap{
        display: none !important;
    }

    .ant-input-number-input{
        text-align: center;
    }
}

.input-producto {
    text-align: center !important;
}

/*
 * Component Radio
 * @description estilos del meta del card del titulo del card
 */
.radio-product span {
    font-family: Poppins;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
    line-height: 23px;
    text-align: center;
    letter-spacing: 0.3px;
    color: #686868;
}

.radio-product .ant-radio-checked .ant-radio-inner::after {
    background-color: #1890FF !important;
}

.radio-product .ant-radio-inner {
    border: 1px solid #1890FF !important;
}


/*
 * Component Button
 * @description estilos del meta del card del titulo del card
 */
.btn-quote {
    font-family: Poppins;
    font-weight: 400 !important;
    background: #0089ED !important;
    min-width: 200px;
    border: 1px solid transparent !important;
    border-radius: 8px;

}

.btn-purple{
    font-family: Poppins;
    font-weight: 400 !important;
    background: #B354E0 !important;
  
    border: 1px solid transparent !important;
    border-radius: 8px;
    
}

.btn-success{
    font-family: Poppins;
    font-weight: 400 !important;
    background: green !important;
  
    border: 1px solid transparent !important;
    border-radius: 8px;
    
}

/*
 * Component TableMatrizCostos
 * @description color de fondo de la tabla y el borde conforme al estilo del resto de la vista
 */
.table-mc .ant-table {
    background: #FFFFFF;
    border-radius: 20px;
}

/*
 * @description estilos del tag table para separar los elementos unos de otros
 */
.table-mc .ant-table table {
    border-spacing: 10px !important;
    border-collapse: inherit;

}

/*
 * @description estilos del primer th del header (th) de la tabla
 */
.table-mc .ant-table .ant-table-thead .ant-table-cell:first-child {
    background: #FFFFFF;
    border: none;
}

/*
 * @description estilos del resto de th del header (th) de la tabla
 */
.table-mc .ant-table .ant-table-thead .ant-table-cell:not(:first-child) {
    text-align: center;
    padding: 5px;
    font-family: Poppins;
    background: #DADADA;
    color: #454545 !important;
    border: none;
    border-radius: 20px;
    height: 14px;
    font-weight: 500 !important;
}

.table-mc .ant-table .ant-table-tbody .ant-table-row {
    min-height: 150px;
}

/*
 * @description Elimina los bordes de la tabla para que se vea uniforme
 */
.table-mc .ant-table .ant-table-tbody>tr>td {
    border-bottom: 5px solid transparent;
    border-top: none !important;
    border-right: none !important;
    border-left: none !important;
    margin: 0.2rem 0;
}


.filter-radio{
    max-width: 500px;
}
.filter-radio .ant-radio-button-wrapper{
    border-radius: 20px!important;
    border: none;
    border-color: #FFFFFF!important;

}

.filter-radio .ant-radio-button-wrapper:not(:first-child)::before{
    width: 0;
}

.filter-radio  .ant-radio-button-wrapper-checked{
    background: rgba(222, 221, 249, 1)!important;
    color: #5C54E0!important;
    border: #FFFFFF;
}

.tabs-inventario .ant-tabs-tab{
    width: auto !important;
    padding: 6px 12px !important;
}

.virtual-list-PM .rc-virtual-list-holder-inner{
    display: block !important;
}

.orden-embarque-elipsis svg{
    width: 2rem;
    height: 2rem;
    color: rgba(84, 84, 84, 0.85) !important;
}