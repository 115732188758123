/*
 * @component Header 
 * @description Cambia el tamaño del header
 */
 .header {
    height: auto;
    min-height: 100px;
    padding: 0px!important;
}

/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen (logo) del header
 */
.logo {
    //max-height: 50px;
}


/*
 * @component Header 
 * @description Cambia el tamaño del logo del header
 */
 .logo img {
    height: 50px!important;
    //margin: 1.5rem 4rem 0rem 4rem;
}

/*
 * @component Header 
 * @description Cambia el tamaño del contenedor de la imagen para que se ajuste al sidebar
 * Si se mueve este estilo, se tiene que modificar tambien el del sider-width
 */
.header-logo {
    min-width: 300px;
    max-width: 280px!important;
    transition: all 0.2s;
}

.header-logo.collapse {
    min-width: 0;
    max-width: 0!important;
}

/*
 * @component Header 
 * @description Cambia estilo del link para hacer login (Iniciar Sesion)
 */
.link-login {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    letter-spacing: 3px;
    text-transform: uppercase;
    color: #FFFFFF;
    background: transparent;
    position: absolute;
    right: 10px;
    top: 15px;
}


/*
 * @component Header 
 * @description Estilos del link (iniciar sesion) al hacer hover
 */
.link-login:hover {
    color: #bababa;
    animation: fadeIn linear 5s;
}


/*
 * @component Header -> InputSearch
 * @description Estilos de la barra de busqueda del header
 */
.header-search {
    display: flex;
    margin: 24px 10px;
    max-width: 437px;
}



/*
 * @component PAgeHeader 
 * @description Hace que el contenedor del title del pageheader abarque todo el espacio
 */
.custom-page-header .ant-page-header-heading .ant-page-header-heading-left, .custom-page-header .ant-page-header-heading .ant-page-header-heading-left .ant-page-header-heading-title {
    width: 100%!important;
}

/*
 * @component PAgeHeader -> extra 
 * @description Para el contenido en extra que sea texto plano
 */
 .custom-page-header .page-header-extra{
    color: #FFF;
    font-size: 11pt;
    font-weight: 600;
    line-height: 1.5;
   vertical-align: sub;
}

/*
 * @component Header -> Menu 
 * @description Alinea los elementos del contenido del header despues del logo
 */
.header-content {
    align-items: center;
    justify-content: space-between;
    background-color: #FFF;
    min-height: 50px;
}

/*
 * @component Header -> Menu 
 * @description Alinea los divs del contenedor de las opciones de usuario (notificaciones/logout)
 */
.header-options div {
    display: inline-block;
    margin: 5px 0;
    vertical-align: middle;
}


/*
 * @component Header -> Menu 
 * @description cambia el estilo del icono de notificaciones
 */
.header-options .icon-bell {
    color: #cccccc;
    font-size: 17pt;
    margin-bottom: -7PX;
}
/*
 * @component Header -> Menu 
 * @description cambia el estilo del bagde de notificaciones
 */
.header-options .ant-badge-count {
    background-color: #5e45d7;
}


/*
 * @component Header -> InputSearch 
 * @description cambia el estilo input de busqueda del header 
 */
.input-global-search .ant-input-group * {
    height: 50px!important;
}


/*
 * @component Header -> InputSearch 
 * @description cambia el estilo button de busqueda del header
 */
.input-global-search .ant-input-group .ant-input-group-addon button {
    background: #D2D4E7;
    border: none;
    margin-left: -7px;
    width: 50px;
    border-radius: 8px !important;
}


/*
 * @component Header -> InputSearch 
 * @description cambia el estilo icono del input de busqueda del header
 */
.input-global-search .ant-input-group .ant-input-group-addon button .anticon svg {
    color: #2D348C;
}

/*
 * @component Header   
 * @description Estilos del header para pantallas pequeñas
 */
@media (max-width: 480px) {
    .header-logo {
        background: #2D348C !important;
        width: 100%;
        height: auto;
        position: relative;
    }
    .header-logo .logo {
        display: block;
        margin: auto;
    }
    .header-content {
        display: flex;
        flex-flow: column;
        align-items: flex-end;
        height: auto !important;
        padding-top: 1rem;
    }
    .header-content .header-search {
        order: 2;
        width: 100%;
        padding: 1rem 2.5rem 1rem 0;
    }
    .header-content .header-options {
        order: 1;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas medianas
 */
@media (max-width: 768px) {
    .header {
        display: contents !important;
        line-height: 1rem !important;
    }
    .header-logo {
        background: #2D348C;
    }
    .header .row-header-content {
        width: 100%;
        background: #2D348C;
    }
}

/*
 * @component Header   
 * @description Estilos del header para pantallas de medianas a largas
 */
@media (max-width: 1200px) and (min-width: 768px) {
    .header .header-logo .logo {
        min-width: auto;
        transition-duration: 1s !important;
    }
}

@media (max-width: 992px) {
    .header .header-logo {
        display: none
    }
}



/*
 * @component HeaderPublic   
 * @description Estilos del header public
 */
 .ant-layout-header.header-public{
    height: unset !important;
    padding: 0 !important;
    line-height: 40px  !important;
    background-color: white !important;


    /*
    * @component HeaderPublic   
    * @description Todas las secciones van dentro de este elemento, para hacerlo responsivo en un futuro
    */
    .header-section-container{
        max-width: 1300px;
        width: 100%;
        margin: 0 auto;
        height: auto;
        padding: 0 1rem;
    }

    /*
    * @component HeaderPublic   
    * @description Esta es la barra de información, donde aparece la info de contacto
    */
    .information-bar{
        color: white !important;
        background-color: #343434;
        height: 30px;
        display: flex;
        align-items: center;

        button, span, a{
            color: white !important;
       }

       &-flex{
        display: flex;
        column-gap: 8px;
        align-items: center;
        height: 26px;

            &.mail{
                justify-content: flex-end;
            }
       }
    }


    /*
    * @component Controlbar   
    * @description barra de control
    */
    .control-bar{

        &-content{
            width: 100%;
        }

        /*
        * @description CSS para la barra de busqueda de productos.
        */
        .search-bar{

            /*
            * @description INPUT de busqueda
            */
            &.ant-input{
                background: white;
                padding: 12px 18px !important;
                border: none !important;
                box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;

                &::placeholder{
                    color: #ADADAD !important;                    
                }
            }

        }
    
        &-actions{
            display: flex !important;
            justify-content: center;
            align-items: center;
            column-gap: 24px;

            .actions{
                &-search{
                    border: none;
                }
            }
        }
    }


    /*
    * @description Para la barrita blanca que contiene el carrito y la barra de catalogos
    */
    .menu-bar{
        background-color: #2D348C;
        font-weight: bold !important;
        height: 74px;
        display: flex;
        align-items: center;

        & .header-section-container{
            height: 100%;
        }

        &-center{
            display: flex;
            justify-content: center;
        }

        &-right{
            display: flex;
            justify-content: flex-end;
        }

        &-dropdown{
            height: 100%;
            width: 100%;
            max-width: 186px;
            background-color: #ED6843;

            display: flex;
            justify-content: center;
            align-items: center;

            *{
                color: white;
                font-size: 18px;
                font-weight: 600;
            }
        }

        &-links *{
            color: white;
            font-size: 18px;
            font-weight: 600;
        }

        &-contact{
            display: flex;
            align-items: center;
            column-gap: 10px;   
            & .contact{
                
                &-icon-wrapper{
                    width: 50px;
                    height: 50px;
                    background-color: #D94A48;
                    border-radius: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &-icon{
                    color: white;
                    font-size: 35px;
                }

                &-content{
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;

                    *{ 
                        color: white;
                        line-height: 24px;
                     }
                }

                &-text{
                    font-size: 14px;
                    font-weight: 500;
                }

                &-number{
                    font-size: 16px;
                    font-weight: 700; 
                }
            }
        }
    }
}

@media (max-width: 992px) {
    .menu-bar{
        &-center{
            display: none !important;
        }
    }
}

@media (max-width: 600px){
    .ant-layout-header.header-public{
        .information-bar{
            height: 50px;
    
            &-flex, &-flex.mail{
                justify-content: center;
            }
        }

        .control-bar{
            &-actions{
    
                .actions{
                    &-search{
                        width: 100%;
                        border: none;
                    }
                }
            }
        }
    }
}
/*
* @component Cotizaodr
* @description Esto es propio del cotizador
*/
.header-input{
    /*
    * @description INPUT de cantidad y de ID
    */
    &.ant-input,
    &.ant-input-number {
        border: 1px solid #C5C5C5;
        border-radius: 3px !important;
        background: none !important;
    }
}

/*
* @description Buton naranja del cotizador, el grandote
*/
.button-cotizador{
    border-radius: 150px !important;
    border: none !important;
    background-color: #E05447 !important;
    margin: 0 auto;
}


/*
* @description Para  la lista de productos, para hacer el span de error mas pequeño
*/
.list-item-producto{

    /*
    * @description EXPLAIN error
    */
    .ant-form-item-explain-error {
        font-size: 12px !important;
    }
}



button.button-floating.whatsapp-button{
    background: linear-gradient(126.63deg, #128c7e 11.98%, #25d366 83.35%) !important;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .whatsapp-button-icon{
        color: white;
        font-size: 50px;
    }
}