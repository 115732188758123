.input-number-modal{
    // .ant-input-number-group-addon{

    // }

    .ant-select-status-error {
        padding-left: 0 !important;
        padding-right: 0 !important;

    }
}