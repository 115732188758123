.quick-quote{
    width: 100%;
    background-image: url('../../../../public/images/quick-quote-bg.png') !important;
    background-size: cover !important;
    overflow: initial;
    background-attachment: fixed !important;
    background-repeat: no-repeat !important;

    display: flex;
    justify-content: center;
    padding: 60px 24px;


    *.ant-typography, *.ant-btn span{
        color: white !important;
    }

    // Lo contenido en el espacio/card azul 
    &-content{
        width: 100%;
        height: min-content;
        max-width: 1000px;
        padding: 3rem 2rem;
        background-color: #2D348C;
    }

    // Titulares principales
    &-header{
        text-align: center;

        .header-title{
            font-size: 40px;
        }
    }

    &-subtitle{
        margin-bottom: 0px;
    }

    &-actions{
        display: flex !important;
        justify-content: flex-end;

        .action-add{
            background-color: #1890FF !important;
            border: none !important;
        }
    }

    &-divider{
        margin: -12px 0px 0px 0px !important;
        color: white !important;
        border-top: solid 1px white !important;
    }

    &-empty{
        display: flex !important;
        flex-direction: column;
        align-items: center;
        padding: 24px;
    }

    &-text-add{
        font-weight: bold;
        text-decoration: underline;
        background-color: transparent !important;
        border: none !important;
    }

    &-productos .ant-list-item{
        border-bottom: none !important;
    }

    &-cotizar{
        background-color: #E05447 !important;
        border: none !important;
        border-radius: 5px !important;
        color: white !important;
        font-weight: 700 !important;
        padding: 0.5rem 3rem !important;
    }
}