.productos{
    &-main{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 45px 24px;

        & > .ant-row{
            max-width: 1300px;
            width: 100%;
        }
    }

    &-sorts{
        display: flex !important;
        justify-content: flex-end;
        align-items: center;
        
        .sorts{
            &-select{
                width: 250px;
            }
        }
    }


    &-filters{
        display: flex !important;
        flex-direction: column;
        row-gap: 24px;

        .filters-collapse{
            .ant-collapse-header{
                padding: 12px 0px !important;
                border-bottom: solid 1px black;

                &-text{
                    font-size: 15px;
                    letter-spacing: 6px;
                    font-weight: 700;
                }
            }

            .ant-collapse-content-box{
                padding: 12px 0px !important;
            }
        }

        .filters-buttons{
            display: flex;
            flex-direction: column;
            row-gap: 5px;

            .ant-radio-button-wrapper{
                border: none;
                border-radius: 0px;
                padding: 0px;
                background-color: transparent;
                color: rgba($color: #000000, $alpha: 0.7);
                height: 24px;
                line-height: 24px;

                &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
                    border: none;
                    border-radius: 0px;
                    font-weight: 600;
                    color: #103B55;

                    &:focus-within{
                        box-shadow: none;
                    }
                }

            }
        }
    }
}

.main-button{
    background-color: #ED6843 !important;
    color: white !important;
    border-radius: 5px;
    border: none !important;
    width: 100%;
    padding: 7px 10px;
    text-align: center;
    font-weight: 600;
    font-size: 18px !important;
}


/*para la vista del detalle del producto*/
.producto-detalle{


    .producto-title{
        height: 128px;
        margin-top: 1rem;
        margin-bottom: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .producto-code{
        font-size: 16px;
        font-weight: bold;
        display: block;
        margin-bottom: 1rem;
        color: gray;
    }

    .producto-price{

        *{
            font-size: 26px !important;
        }

        font-weight: bold;
        display: block;
        margin-bottom: 1.5rem;
    }

}


/*Para la vista del carrito de compra del cliente*/
.carrito-compra{

    .carrito-total{
        font-weight: bold;
        font-size: 16px !important;
        margin-bottom: 0.5rem;
        * {
            color: #2d348c !important;
        }
    }

    .ant-list-item{
        border-bottom: 1px solid #bdb6b6 !important;
    }

    .producto-title{
        font-size: 18px;
        font-weight: bold;
        display: block;
        min-height: 62px;
        overflow: hidden;
    }

    .producto-price-col{
        height: 68px;
        display: flex;
        justify-content: start;
    }

    .carrito-label{
        color: gray;
    }

    .producto-price{

        *{
            font-size: 14px !important;
        }
    }

    .producto-cantidad-col{
        height: 68px;
        display: flex;
        justify-content: start;
        align-items: end;
        padding-bottom: 12px;
    }

}

.label-action-gray{
    color: gray;
    text-decoration: underline;
    margin-left: 8px;
    cursor: pointer;

    &:hover{
        color: #2d9dc1;
    }
}