.card-saldo {
    min-height: 180px;
}

.card-saldo.list .ant-card-body{
    padding: 12px;
}

.card-saldo .ant-card-body .card-saldo-container{
    padding: 12px;
    max-height: 1200px;
    overflow: auto;
}

.table-orden-compra .ant-table-cell{
    padding: 10px 16px !important;
}


.form-item-orden-compra{    
    margin: 0 !important;
}

.form-item-orden-compra .ant-form-item-explain{
    position: absolute;
    bottom: -25px;
}


.form-item-orden-compra .ant-form-item-explain-error {
    font-size: 12px;
}
