.sec-mensajes {
    min-height: 90vh;
}

.sec-mensajes>.ant-row>.ant-col {
    /*padding:10px;*/
}

.card-msg {
    /*margin-bottom: 1.5rem*/
}

.abstract-msg {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.radioMensajeTipo {
    width: 65%;
    max-width: 400px;
    margin: auto;
    margin: 20px auto;
}

.radioMensajeTipo .ant-radio-button-wrapper:first-child {
    border-radius: 10px 0px 0px 10px;
}

.radioMensajeTipo .ant-radio-button-wrapper:last-child {
    border-radius: 0px 10px 10px 0px;
}

.radioMensajeTipo .ant-radio-button-wrapper {
    width: 50%;
    text-align: center;
    height: 40px;
    padding-top: 3px;
    font-size: 12pt;
    background: #f4f8fb;
    border: solid 1.5px #cad7df;
}


.radioMensajeTipo .ant-radio-button-wrapper:hover {
    color: #66bd79;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    background: #fff;
    color: #555;
    border-color: #03e033;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
    border-right-color: #03e033;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    border-color: #03e033 !important;
}

.radioMensajeTipo .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #03e033 !important;
}


.cnt-card-mensaje {
    width: 100% !important;
    margin-bottom: 14px;
}

.cnt-card-mensaje .ant-card {
    background: #FFFFFF;
    box-shadow: 0px 24px 48px rgba(25, 29, 56, 0.10);
    border-radius: 8px;
}

.cnt-card-mensaje .ant-card .ant-card-body .ant-comment-avatar {
    display: block;
    margin: auto;
    padding: 5px;
}
.cnt-card-mensaje .ant-card .ant-card-body{
   padding: 0px 8px;
}

.cnt-card-mensaje .ant-badge .ant-card .ant-card:hover {
    box-shadow: 0px 24px 48px rgba(25, 29, 56, 0.2);
}

.cnt-card-mensaje.selected .ant-badge .ant-card {
    border: 1px solid rgba(143, 167, 194, 0.239);
    background: rgba(143, 167, 194, 0.239);
}

.cnt-card-mensaje .ant-card-head {
    padding: 0px 15px;
}


.cnt-card-mensaje .ant-card-head-title {
    padding: 5px 0px;
}

.cnt-card-mensaje .ant-card-head-title>div {
    width: 100%;
    margin-bottom: 10px;
}

.cnt-card-mensaje .ant-card-head-title h2 {
    padding: 0px;
    margin: 0px;
    margin-top: 15px;
    font-size: 14pt;

}

.cnt-card-mensaje .ant-card-head-title h3 {
    padding: 0px;
    margin: 0px;
    margin-top: 0px;
    font-size: 11pt;
    font-weight: normal;
    font-style: normal;
    color: #737588;
}

.cnt-card-mensaje p {
    color: #737588;
    font-size: 12pt;
}


.cnt-Mensajes {
    border-radius: 8px;
    color: #191D38;
    background: linear-gradient(#fff 80%, rgba(255, 255, 255, 0.98)) !important;
    min-height: 500px;
    max-width: 100%;
}

.cnt-Mensajes-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0px 10px 15px rgba(51, 51, 51, 0.205);
}

.cnt-Mensajes-header h2,
.cnt-Mensajes-header h5 {
    margin: 0 0 0 14px;
    font-size: 18pt;
}


.cnt-Mensajes-header .ant-avatar {
    background: #03E033;
    margin-Top: 10px;
    width: 55px;
    height: 55px;
    margin-Left: 25px;
    margin-bottom: 20px;
}


.colTime {
    text-align: right;
}


.cnt-msj {
    width: 100% !important;
    border: none;
    border-bottom: solid 1px #6bb67d3b;
}

.customer .cnt-msj .ant-card-body {
    padding: 0.5rem;
}

.card-msj .ant-avatar {
    width: 30px;
    height: 30px;
    margin: 0px !important;
    padding: 0px;
}

.msj-emp .ant-avatar {
    background: #03E033;
}

.card-msj h3 {
    color: #9798a3;
    font-size: 10pt;
    font-weight: normal;
    font-style: normal;
}

.card-msj p {
    line-height: 2;
}

.btn-downloadFile {
    background: #B165E9;
    mix-blend-mode: normal;
    border-radius: 5px;
    border: none;
    width: 45px;
    height: 45px;
    padding: 0px;
    color: white;
    margin: 0px;
}

.btn-downloadFile:hover,
.btn-downloadFile:focus,
.btn-downloadFile:active {
    background: #bb7ae9;
    border: none;
    color: white;
}

.cnt-Mensajes-body {
    min-height: 60vh;
    max-height: 60vh;
    overflow: auto;
}

.cnt-Mensajes-body .cnt-msj {
    min-height: 60vh;
}

.cnt-Mensajes-opciones {
    display: inline-flex;
    border-radius: 5px 5px 5px 5px;
    padding: 10px;
    background: white;
    box-shadow: 0px 10px 15px rgba(51, 51, 51, 0.205);
    width: 100%;
}

.customer .cnt-Mensajes-opciones {
    display: flex;
    flex-direction: column;
}

.cnt-Mensajes-opciones .ant-avatar {
    width: 45px !important;
    height: 45px !important;
    margin-right: 10px;
}


.cnt-Mensajes-opciones .ant-input {
    width: auto !important;
    max-height: 40px !important;
    min-width: calc(100% - 172px);
    background: transparent;
    padding-top: 10px !important;
}

.cnt-Mensajes-opciones .ant-input::placeholder {
    color: #666;
}

.cnt-msj-opciones {
    width: 120px;
    padding-top: 6px;
    float: right;
}

.customer .cnt-msj-opciones {
    width: 100%;
    padding-top: 6px;
    display: flex;
    justify-content: space-between;
}

.cnt-msj-opciones .ant-btn {
    width: 30px;
    height: 30px;
    padding: 0px;
    padding-top: 2px;
    background: rgba(255, 255, 255, 0.178);
    margin-left: 9px;
    border: none;
}

.customer .cnt-msj-opciones .ant-btn {
    margin-left: 0px;
}


.owner .ant-comment-avatar {
    margin-left: auto;
    order: 2;
    
}

.owner .ant-comment-content-author {

    justify-content: flex-end;
}


.owner .ant-comment-content-author-name {
    order: 2;
}

.owner .ant-comment-content-detail {
    position: relative;
    right: 8px;
    text-align: end;
}

.avatarPE {
    margin: 0px !important;
    padding: 0px !important;
}

.avatarPE img {
    width: 55px;
    height: 55px;
    margin: 0px !important;
    padding: 0px;
}



.filesList {
    padding: 1.7em 1em 1em 1em;
    margin-top: 0.7em;
    border-top: 1px solid #e6e6e6;

}

.filesList .file-container {
    margin-left: 1em;
    display: inline-block;
}

.filesList .file {
    text-align: center;
    max-width: 230px;
    /*height: 100px;*/
    border: 1px solid #dedede;
    border-radius: 10px;
    position: relative;
}


.filesList .file>svg {
    font-size: 35px;
    text-align: center;
    position: relative;
    top: calc(50% - 15px);
    color: #b5a9a9;

}

.filesList .file .buttom-close {
    margin: 0;
    padding: 0;
    width: 25px !important;
    height: 25px !important;
    color: white;
    border: none;
    background: #dc3545 !important;
    border-radius: 100%;

    position: absolute;
    right: -8px;
    top: -8px;
    /*     top: -10px: */

}

.filesList .file .buttom-close svg {
    font-size: 18px;
    position: relative;
    top: 2px;
}




.ant-comment-content-author,
.ant-comment-content-author .ant-comment-content-author-name,
.ant-comment-content-author .ant-comment-content-author-time {
    width: 100% !important;
    float: left !important;
}


.owner .ant-comment-content-author,
.owner .ant-comment-content-author .ant-comment-content-author-name,
.owner .ant-comment-content-author .ant-comment-content-author-time {
    float: right !important;
    display: block;
    text-align: right;
}



.ant-comment-content-author-name h1,
.ant-comment-content-author-name h2 {
    font-size: 13px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.7);
    display: inline-block !important;

}

.ant-comment-content-author-name h2 {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.4);
    margin-left: 10px;
}