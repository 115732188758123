.collapse-almacenes .ant-collapse-item{
    margin-bottom: 0.5rem;
}

.collapse-almacenes .ant-collapse-header {
   display: flex;
    background: #2D348C;
    border: 0px;
    border-radius: 8px!important;
    color: #FFFFFF;
    
}
.collapse-almacenes .ant-collapse-item .ant-collapse-content{
    background: #FFFFFF;
    font-family: Poppins;
    font-style: normal;
    font-size: 15px;
    vertical-align: middle;
    line-height: 50px;
    color: #FFFFFF ;
    font-weight: 600;
}

.header-collapse span {
    font-family: Poppins;
    font-style: normal;
    font-size: 13px;
    line-height: 19px;
    color: #FFFFFF ;


}

.collapse-text-big {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 18px!important;
    line-height: 24px;
}
.collapse-text-medium{
    font-size: 15px!important;
}

.collapse-icon-big svg{
    font-size: 25px;
}

.collapse-almacenes .ant-collapse-item{
    border:  none !important;
}

.tabs-inventario .ant-tabs-nav-list{
    background: white;
    padding: 0.8rem;
    border-radius: 10px;
}

.tabs-inventario .ant-tabs-tab-active{
    background: #deddf9 !important;
    color: #5C54E0 !important;
    border: #FFFFFF;
    border-radius: 30px;
    padding: 0 10px;
    width: 100px;
    text-align: center;
    justify-content: center;
    margin: 0 !important;
}
.tabs-inventario .ant-tabs-tab{
    width: 100px;
    margin: 0 !important;
    justify-content: center;
    text-align: center;
}

.tabs-inventario .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #5C54E0 !important;
}

.tabs-inventario .ant-tabs-ink-bar{
    display: none;
}

.lista-vmi .ant-spin-container{
    min-height: 200px !important;
}